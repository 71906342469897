module.exports = {

    wakuang1: {
        wodezhanghao: 'My account',
        tuijianren: 'Referrer',
        fengxiang: 'Share',
        dengjijindu:'Level progress',
        dangqiandengji:'Current level',
        haixu:'Still needed',
        shengji:'Upgrade',
        wodedaibi: 'My token',
        wodeETF: 'My ETF',
        zongji:'Total',
        jiazhi:'Value',
        wodekuangchi: 'Mining pool',
        wodesuanli: 'Computing power',
        yilingqujiangli: 'Already claimed (U)',
        wodeshouyi: 'My earnings',
        jiasushouyi: 'Accelerated earnings',
        zongji: 'Total (ETF)',
        jiazhi:'Value (U)',
        zongkuangchi:'Total mining pool',
        chongzhinengliang: 'Recharge energy',
        wodetuandui: 'My team',
        wodezhitui: 'Direct promotion',
        zhishuxiashu: 'Subordinates',
        dengji:'Level',
        renshu:'Performance',
        jine: 'Amount',
        jianglimingxi: 'Reward details',
        ETFlingqu: 'ETF claim',
        shuliang: 'Quantity',
        shijian: 'Time',
        dangqianjiage: 'Current price',
    },
    wakuang2: {
        duihuan: 'Exchange',
        jishijiaoyidaibi: 'Instant trading token',
        yue: 'Balance',
        huadianshangxian: 'Slippage limit',
        jiaoyi: 'Transaction',
    },
    guanwang1: {
        us1: 'The world s leading decentralized financial platform',
        us2: 'Contract',
        us3: 'Join the affiliate company',
        us4: 'Our goal',
        us5: 'Choose exchange-traded funds as your financial leverage with less initial capital',
        us6:'·ETF mining',

        us7:'Dec 2024',
        us8: '·IM communication',
        us9: 'Mar 2025',
        us10: '·ETF public chain testnet',
        us11: 'Jul 2025',
        us12: 'ETF mainnet',
        us13: 'Dec 2025',
        us14:'ETF ecosystem',
        us15:'Apr 2026',
        us16: 'ETF token economics',
        us17: 'Token distribution',
        us18: 'Total supply',
        us19: 'Mining',
        us20:'Liquidity',
        us21:'Performance',
        us22: 'Why develop the ETF public chain',
        us23: 'To enable ETF to be executed on the chain, which will be more open and fair',
        us24: 'Can ETF be executed on other public chains',
        us25: 'It can be realized. However, due to the mechanism characteristics of the current public chains, it will be arbitraged by nodes',
        us26: 'The difference between the ETF public chain and the EVM public chain',
        us27: 'Modified the GAS mechanism to ensure that nodes cannot queue in advance, making finance more fair and secure',
        us28: 'The reason why ETF has not been executed on the public chain',
        us29: 'The current queuing mechanism of EVM allows nodes to queue in the internal network, which can arbitrage 100%',
        us30: 'Contact us',
        hetong: 'contract',
        partr1name: "Dec 2024",
        partr1text: "MFT Mining",
        partr2name: "Mar 2025",
        partr2text: "IM",
        partr3name: "Jul 2025",
        partr3text: "MFT public chain test network",
        partr4name: "Dec 2025",
        partr4text: "Mainnet",
        partr5name: "Apr 2026",
        partr5text: "MFT Ecosystem",
        tzfp: "Token Allocation",
        zgyl: "Total Supply",
        wj: "Digging",
        ldx: 'Liquidity',
        part4q1: 'Why develop MFT public chain?',
        part4a1: 'In order to make MFT executed on the chain, it will be more open and fair',
        part4q2: 'Can MFT be executed on other public chains?',
        part4a2: 'It can be realized, but due to the characteristics of the current public chain mechanism, it will be arbitraged by nodes',
        part4q3: 'The difference between MFT public chain and EVM public chain',
        part4a3: 'Modified the GAS mechanism to ensure that nodes cannot queue up in advance, making finance fairer and safer',
        part4q4: 'The reason why MFT has not been implemented on the public chain',
        part4a4: 'The current EVM queuing mechanism allows nodes to queue up in the intranet, which allows 100% arbitrage',
        whjyjj: 'Foreign ETF',
        qqlx: 'world leading',
        qzxh: 'decentralized financial platform',
        us501: 'Choosing an ETF',
        us502: 'As your financial leverage, the initial capital is less',
        q1: 'Why develop ETF public chain',
        a1: 'Allowing ETFs to be executed on-chain will be more open and fair',
        q2: 'Can ETFs be executed on other public chains?',
        a2: 'It is feasible, but due to the characteristics of the current public chain mechanism, it will be arbitraged by nodes',
        q3: 'The difference between ETF public chain and EVM public chain',
        a3: 'Modified the GAS mechanism to ensure that nodes cannot queue up in advance, making finance fairer and safer',
        q4: 'Reasons why ETF is not implemented on the public chain',
        a4: 'EVM current queuing mechanism allows nodes to queue up in the intranet, enabling 100% arbitrage',
    },
    guanwang2: {
        us111: 'Homepage',
        us222: 'Whitepaper',
        us333: 'Foundation',
        us444: 'Promotion',
        us555: 'Mining',
        us666: 'Contact us',
        us777: 'Copy successful',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Recruitment of super node partners',
    wodedizhi: 'My address',
    wodezhanghao: 'My account',
    shouquandizhi: 'Authorization address',
    shifoushichaojijiedian: 'Whether it is a super node',
    shichaojijiedian:'Is a super node',
    bushichaojijiedian:'Not a super node',
    dianjijiaru: 'Click to join',
},
    xuanfa: {
    baocuntupian:'Save picture',
    baocunchenggong:'Save successful',
},
    tishi: {
    us888:'Setting successful',
    us999:'Please connect the wallet',
    us1111:'Copy successful',
    us2222:'Cancel operation',
    us3333:'Cannot bind with yourself!',
    us4444:'Please connect the wallet first',
    us5555:'Please enter the correct address',
    us6666:'Joined successfully!',
    us7777:'The current address is already a super node!',
    us8888:'Please enter a valid address',
    us9999:'Exchange successful',
    us11111:'Binding...',
    us22222:'Authorizing...',
    us33333:'Paying...',
    },
    yilou: {
        us44444: 'My earnings (frozen)',
        us55555: 'Upgrading...',
        us66666: 'Receiving...',
        us77777: 'Joining...',
        us88888: 'Exchanging...',
        us99999: 'YES',
        us111111: 'Close'
    },
    head: {
        connect: 'connect'
    }
}