<template>
  <div id="app" :class="{ bgNone: !bgState }">

    <!-- <CutInternet ref="CutInternet"></CutInternet> -->

    <pageHeader :columns="columns" @show="showPicker = true" @callUs="showShare = true"></pageHeader>

    <router-view />

    <!-- <BottomNav></BottomNav> -->


    <LoadingModule></LoadingModule>


    <van-share-sheet v-model="showShare" 
      :title="$t(`guanwang2.us666`)" :cancel-text="$t(`yilou.us111111`)" :options="options" @select="onSelectShare" style="background-color: #fff!important;" />

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker confirm-button-text="√" cancel-button-text="×" show-toolbar :columns="columns"
        @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import { ethers } from 'ethers'
import CutInternet from '@/components/CutInternet.vue'
import pageHeader from '@/components/Header.vue'
import { GetWalletAddress } from "@/assets/js/contractMethods.js";
import BottomNav from "./components/BottomNav.vue";
import LoadingModule from "./components/LoadingModule.vue";

import tgImage from "./assets/img/tg.png"
import twImage from "./assets/img/tw.png"
import emailImage from "./assets/img/email.png"
import osImage from "./assets/img/logo-small.png"

export default {
  name: "App",
  components: {
    CutInternet,
    pageHeader,
    BottomNav,
    LoadingModule,
  },
  data() {
    return {
      show: false,
      showPicker: false,
      columns: [
        { text: 'العربية', value: '阿拉伯语' },
        { text: 'Brasil', value: '巴西语' },
        { text: 'Polska', value: '波兰语' },
        { text: 'Deutsch', value: '德语' },


        { text: 'Русский', value: '俄语' },
        { text: 'Français', value: '法语' },


        { text: 'Filipino', value: '菲律宾' },
        { text: 'Suomi', value: '芬兰语' },
        { text: '한국어', value: '韩语' },


        { text: 'Nederlands', value: '荷兰语' },
        { text: 'Romance', value: '罗曼娜' },


        { text: 'Magyar nyelv', value: '马扎尔语' },
        { text: 'Português', value: '葡萄牙语' },
        { text: '日本語', value: '日本语' },

        { text: 'Sverige', value: '瑞典' },
        { text: 'Türkçe', value: '土耳其语' },
        { text: 'Zimbabwe', value: '津巴布韦' },

        { text: 'Українська мова', value: '乌克兰语' },
        { text: 'Español', value: '西班牙语' },
        { text: 'Italiano', value: '意大利语' },

        { text: 'Bahasa Indonesia', value: '印度尼西亚语' },
        { text: 'English', value: '英语' },
        { text: 'Tiếng Việt', value: '越南语' },
        { text: '繁体中文', value: '繁体中文' },
        { text: '简体中文', value: '简体中文' },
      ],
      


      showShare: false,
      options: [
        { name: 'Email', icon: emailImage, url: 'etf.digital.token@gmail.com' },
        { name: 'TG', icon: tgImage, url: 'https://t.me/ETFDigital' },
        { name: 'TW', icon: twImage, url: 'https://twitter.com/ETFDigital' },
        { name: 'OS', icon: osImage, url: 'www.digital-etf.com' },
      ],
    }
  },
  computed: {
    wallet() {
      return this.$store.state.wallet;
    },
    bgState() {
      return this.$route.path !== "/" && this.$route.path !== "/My";
    },
    bindState() {
      return this.$store.state.bindState;
    },
    bindWindowState() {
      return this.$store.state.bindWindowState;
    },
  },

  created() {
    // this.$global.testBind();
  },
  async mounted() {
    // if (this.wallet) {
    const address = await GetWalletAddress();
    this.$store.commit("SetWalletAddress", address);
    // }
    this.fn();

    // setTimeout( () => {
    //   this.$refs.CutInternet.close()
    // }, 300)
  },

  methods: {
    onConfirm(e) {
      this.$i18n.locale = e.value;
      this.$store.commit('SET_language', e.value)
      this.showPicker = false
    },

    onSelectShare(item) {
      this.$global.CopyContent(item.url, () => {
          this.showSuccessMessage(this.$i18n.t('tishi.us1111'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'))
      })
    },
    fn() {
      // console.log('ethereum', ethereum);
      ethereum.on("accountsChanged", () => {
        // console.log('', 1344);
        window.location.reload();
      });
      //监听链网络改变
      ethereum.on("chainChanged", (e) => {
        // console.log(e)
        // console.log("链切换");
        window.location.reload();
      });
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/base.scss";
@import "./assets/css/style.scss";

#app {
  width: 100vw;
  min-height: 100vh;
  max-width: 400px;
  margin: 0 auto;

  background-color: #010e1e;

  &.bgNone {
    // background-image: none;
  }
}
</style>